<template>
  <b-card>
    <real-estate-client-form v-model="client" />

    <!-- BUTTONS -->
    <b-row class="my-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" :disabled="!canSave" @click="save">
          Crear
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import RealEstateClientForm from "./components/RealEstateClientForm.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    RealEstateClientForm,
    BButton,
  },
  data() {
    return {
      client: null,
    };
  },
  computed: {
    canSave() {
      return (
        !!this.client?.firstName &&
        !!this.client?.operation &&
        (!!this.client?.email || !!this.client?.phone)
      );
    },
  },
  methods: {
    cancel() {
      this.$router.push({ name: "foravila-real-estate-clients-list" });
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("realEstateClient/createClient", {
          firstName: this.client?.firstName,
          lastName: this.client?.lastName || null,
          email: this.client?.email || null,
          phone: this.client?.phone || null,
          operation: this.client?.operation || null,
          interests: this.client?.interests || [],
          cities: this.client?.cities || [],
        })
        .then((client) =>
          this.$router.push({
            name: "foravila-real-estate-client-view",
            params: { clientUuid: client.uuid },
          })
        )
        .catch(() =>
          notifyError(
            "Error",
            "El client no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
